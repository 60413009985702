import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { debounce } from "lodash";

export default function useList() {
  const { t } = useI18nUtils();
  const trans = {
    "messages.error.server_error": t("messages.error.server_error"),
  };
  const refListTable = ref(null);
  const toast = useToast();
  const tableColumns = [
    { label: "traffic.request", key: "request", sortable: true },
    {
      label: "traffic.created",
      key: "created",
      sortable: true,
      formatter: (value, key, item) => {
        return moment(value).format("DD/MM/YYYY HH:mm:ss");
      },
    },
    { label: "traffic.imei", key: "imei", sortable: true },
    { label: "traffic.ip", key: "ip", sortable: true },
    { label: "traffic.alarm", key: "alarm", sortable: true },
  ];

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const activeFilter = ref(null);
  const action = ref("list");

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {
    store
      .dispatch("traffic/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        active: activeFilter.value,
        action: action.value,
      })
      .then((response) => {
        if (action.value == "export") {
          action.value = "list";
        } else {
          callback(response.data);
          totalList.value = response.meta.total;
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: trans["messages.error.server_error"],
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch([ activeFilter, currentPage, perPage ], () => {
    refetchData();
  });

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    activeFilter,
    refetchData,
    action,
  };
}
