<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50">
      <h5>Filtros</h5>
    </b-card-header>
    <b-card-body>
      <b-row align-v="center" class="d-flex align-items-center">
        <b-col cols="4">
          <label class="text-capitalize">{{ $t("traffic.alarm") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="activeFilter"
            class="w-100"
            :reduce="(val) => val.value"
            :options="[
              { label: 'true', value: 1 },
              { label: 'false', value: 0 },
            ]"
            @input="(val) => $emit('update:activeFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { integer } from "@/@core/utils/validations/validations";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormCheckbox,
    vSelect,
  },
  props: {
    activeFilter: {
      type: integer,
      default: null,
    },
  },
};
</script>